.btn_close {
    border-radius: 4px;
    border: 1px solid var(--neutral-500, #D0D0CE);
    background: var(--shades-0, #FFF);
    color: var(--shades-100, #000);
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    padding: 9px 15px;
}

.modal-content {
    top: 0px;
}

.modal-body p {
    /* color: #45454D; */
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
}

.modal-body h3 {
    color: #45454D;
    font-size: 22px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.2px;
    padding: 10px 0px;
}

.modal-header {
    padding: 15px 38px;
}

.modal-footer {
    padding: 10px 38px;
}

.modal-body {
    padding: 20px 20px;
    max-height: 577px;
    overflow: scroll;
    overflow-x: hidden;
}

.modal-title {
    color: #1B1B1E;
    font-size: 32px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

#addressPlaceholder,
#phonePlaceholder,
#emailPlaceholder {
    text-align: center;
    margin: 15px auto;
    color: #1b1b1e;
    font-weight: 700;
}

#contactText {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1b1b1eCC;
    text-align: center;
}

.contact_modal_input {
    padding: 13px 16px 13px 22px;
    background: #F5F5F5;
    border-radius: 16px;
    border: none;
}

.inputLableSty {
    color: #1B1B1E;
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 15px 5px 5px 5px;
}

.btnSubmitSty {
    background: #34B406;
    border: 1px solid #34B406;
    border-radius: 16px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    padding: 15px !important;
    border: none;
    cursor: pointer;
}

form .error_color {
    width: 100%;
    list-style-type: none;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    font-weight: 500;
    line-height: 17px;
    color: #ff2b2b;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 8px 10px;
    text-align: start;
    transition: all 0.2s ease;
    margin-bottom: 0px;
}

.AddPaddingContactForm {
    padding: 40px 60px;
}

.input_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.input_main img {
    position: absolute;
    left: 12px;
}

@media only screen and (max-width: 991px) {
    .AddPaddingContactForm {
        padding: 40px 0px;
        margin: 0px -10px;
    }

    #contactText {
        margin: 0px 0px;
    }

    form .error_color {
        font-size: 12px;
        line-height: 12px;
        padding: 10px 0px;
    }

    .modal-title {
        font-size: 20px;
    }

    .modal-header {
        padding: 15px;
    }

    .modal-body {
        max-height: 600px;
        overflow-x: hidden;
    }
}

/*  */

.modal.fade .modal-dialog {
    transition: transform .5s ease-out;
    transform: translate(0, 500px);
}

.modal-dialog {
    position: relative !important;
    bottom: 0 !important;
    left: 0% !important;
    right: 0% !important;
    margin-bottom: 0 !important;

}

.modal.show .modal-dialog {
    transform: none;
}

.modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

}



/* @media only screen and (max-width: 991px) {
    #contactText {
        margin: 0px -15px;
    }
} */