.main-div {
    height: auto;
    min-height: 100vh;
    width: 100%;
}

/* loader css */
/* .loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}
.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} */

.main-div {
    height: auto;
    min-height: 100vh;
    width: 100%;
}

.dots {
    position: relative;
    width: 150px;
    height: 50px;
    transform: scale(0.25, 0.25);
}

.dot {
    width: 53px;
    height: 53px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    border-radius: 50%;
    background-color: #EB275F;
}

.dot-1 {
    animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
    animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
    animation: animation-dots 1.5s infinite linear;
}

@-moz-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@-webkit-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@-o-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}